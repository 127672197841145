import { BookingInformation } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import {
  USER_GET_BOOKING_DATA,
  USER_UPDATE_EVENTS,
} from '../mutationTypes';

const state = {
  upcomingBookings: [],
};

const mutations = {
  [USER_GET_BOOKING_DATA](stateData, data) {
    stateData.upcomingBookings = [
      ...data,
    ];
  },
  [USER_UPDATE_EVENTS](stateData, data) {
    stateData.upcomingBookings = [
      ...data,
    ];
  },
};

const actions = {
  async getUpcomingBooking({ commit }) {
    const response = await BookingInformation.showUserBookings();
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return;
      }
      commit(USER_GET_BOOKING_DATA, response.data);
    }

    return response;
  },
  async cancelBooking({ commit, state: stateData }, data) {
    const response = await BookingInformation.cancelBooking({
      identification: data,
      eleminator: 'p',
    });
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return;
      }
      const payload = stateData.upcomingBookings.slice().filter((item) => item.identification !== data);
      commit(USER_UPDATE_EVENTS, payload);
    }
    return response;
  },
  async alterBooking({ commit, state: stateData }, data) {
    const response = await BookingInformation.alterBooking(data);
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return Promise.reject();
      }
      const payload = stateData.upcomingBookings.slice().map((item) => {
        if (item.identification === data.identification) {
          return {
            ...data,
            activityName: data.type,
            bookingDay: data.day,
          };
        } else {
          return { ...item };
        }
      });
      commit(USER_UPDATE_EVENTS, payload);
    }

    return response;
  },
};

export const getters = {
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
