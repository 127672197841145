export function isMobileDevice() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth < 768;
  return isMobile;
}

export function isObjectEmpty(obj) {
  if (obj === undefined || obj === null) {
    return true;
  }
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function getRangeMinMax(start, end, min = null, max = null) {
  if (start > end) {
    let tmp = start;
    let array = [];
    const length = (max - start + 1) + (end - min + 1);

    for (let i = 0; i < length; i ++) {
      if (tmp <= max) {
        array.push(start + i);
        tmp++;
      } else {
        array.push(max - tmp + i - (max - start));
      }
    }

    return array;
  }

  return Array(end - start + 1).fill().map((_, index) => start + index);
}

export function getOrdinalNumber(number) {
  const ordinals = ['th','st','nd','rd'];
  const value = number % 100;
  return number + (ordinals[(value-20) % 10] || ordinals[value] || ordinals[0]);
}

export function getFilenameFromHeader(disposition, dispositionType = 'inline') {
  if (disposition.length === '' && disposition.indexOf(dispositionType) === -1) {
    return '';
  }

  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition);

  return matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : '';
}

export function isUserLoggedIn() {
  return localStorage.getItem('user_access');
}

export function getLoginUserData() {
  if (!isUserLoggedIn()) {
    return null;
  }

  const session = localStorage.getItem('user_access');
  const decodeJsonString = atob(session);
  return JSON.parse(decodeJsonString);
}