import { apiClient, apiAdventureGolfAdmin, apiSimple, apiFile, apiDownloadFile } from '@/services';

export const AdminInformation = {
  checkCloseDate(data) {
    return apiAdventureGolfAdmin.get('/showClosingDays/parameter', {
      params: {
        yearMonth: data,
        activityName: 'Adventure Golf',
      },
    });
  },
  showBookings({ dateFrom, dateTo, type }) {
    return apiAdventureGolfAdmin.post('/showBookings/', {
      dateFrom: dateFrom,
      dateTo: dateTo,
      type: type,
    });
  },
  showAdventureBookingOverview({ startDate, endDate }) {
    return apiAdventureGolfAdmin.post('/showAdventureBookingOverview/', {
      startDate,
      endDate,
    });
  },
  showNotices({ dateFrom, activityType, statusType }) {
    return apiAdventureGolfAdmin.get('/showNotices/', {
      params: {
        dateFrom: dateFrom || '',
        activityType: activityType,
        statusType: statusType || '',
      },
    });
  },
  addBooking({ day, timeFrom, timeTo, type, information, name, company, telephone, email, filters = {}, pageConfig, teamSize }) {
    return apiAdventureGolfAdmin.post('/addBooking/', {
      day: day,
      timeFrom: timeFrom,
      timeTo: timeTo,
      type: type,
      information: information || '',
      discriminator: 'admin',
      name: name || 'admin',
      company: company || '',
      telephone: telephone || '',
      email: email || '',
      teamSize: teamSize || '',
      ...filters,
      pageConfig: pageConfig,
    }, {
      headers: {
        'Authorization': `${sessionStorage.getItem('admin')}`,
      },
    });
  },
  showAllEvents({ dateFrom, dateTo, filters = {} }) {
    return apiAdventureGolfAdmin.post('/showAllEvents/', {
      dateFrom: dateFrom || '',
      dateTo: dateTo || '',
      ...filters,
    });
  },
  cancelBooking({ identification, eliminator }) {
    return apiClient.post('/cancelBooking/', {
      identification: identification,
      eliminator: eliminator || 't',
    }, {
      headers: {
        'Authorization': `${sessionStorage.getItem('admin')}`,
      },
    });
  },
  alterBooking({ identification, day, timeFrom, timeTo, type, information, name, company, telephone, email, teamSize, pageConfig, filters = {} }) {
    return apiAdventureGolfAdmin.post('/alterBooking/', {
      identification: identification,
      day: day,
      type: type,
      timeFrom: timeFrom,
      timeTo: timeTo,
      information: information || '',
      name: name || 'admin',
      company: company || '',
      telephone: telephone || '',
      email: email || '',
      teamSize: teamSize || '',
      ...filters,
      pageConfig: pageConfig,
    }, {
      headers: {
        'Authorization': `${sessionStorage.getItem('admin')}`,
      },
    });
  },
  makeNotice({ startDate, endDate, activityType, statusType, information }) {
    return apiAdventureGolfAdmin.post('/makeNotice/', {
      startDate,
      endDate,
      activityType,
      statusType,
      information: information || '',
    });
  },
  removeNotice({ identification }) {
    return apiAdventureGolfAdmin.delete(`/removeNotice/${identification}`);
  },
  showRecurrentEvents() {
    return apiClient.post('/showRecurrentEvents/');
  },
  addRecurrentEvents({ weekNumber, type, timeFrom, timeTo, dateFrom, dateTo, information, name, company, telephone, email }) {
    return apiClient.post('/addRecurrentEvents/', {
      weekNumber: weekNumber,
      type: type,
      timeFrom: timeFrom,
      timeTo: timeTo,
      dateFrom: dateFrom,
      dateTo: dateTo,
      information: information || '',
      name: name || 'admin',
      company: company || '',
      telephone: telephone || '',
      email: email || '',
    });
  },
  alterRecurringEvent({ discriminator, weekNumber, type, timeFrom, timeTo, dateFrom, dateTo, information, name, company, telephone, email  }) {
    return apiClient.post('/alterRecurringEvent/', {
      discriminator: discriminator,
      weekNumber: weekNumber,
      type: type,
      timeFrom: timeFrom,
      timeTo: timeTo,
      dateFrom: dateFrom,
      dateTo: dateTo,
      information: information || '',
      name: name || 'admin',
      company: company || '',
      telephone: telephone || '',
      email: email || '',
    });
  },
  removeRecurringEvent({ discriminator }) {
    return apiClient.post('/removeRecurringEvent/', {
      discriminator: discriminator,
    });
  },
  showRecurringEventsDetails({ discriminator }) {
    return apiClient.post('/showRecurringEventsDetails/', {
      discriminator: discriminator,
    });
  },
  validateRecurringEventPossibility({ weekNumber, type, timeFrom, timeTo, dateFrom, dateTo, information, discriminator }) {
    return apiClient.post('/validateRecurringEventPossibility/', {
      discriminator: discriminator,
      weekNumber: weekNumber,
      type: type,
      timeFrom: timeFrom,
      timeTo: timeTo,
      dateFrom: dateFrom,
      dateTo: dateTo,
      information: information || '',
    }, {
      headers: {
        'Authorization': `${sessionStorage.getItem('admin')}`,
      },
    });
  },
  validateBookingPossibility({ identification, day, timeFrom, timeTo, type, information }) {
    return apiClient.post('/validateBookingPossibility/', {
      discriminator: 'admin',
      identification: identification || '',
      type: type,
      timeFrom: timeFrom,
      timeTo: timeTo,
      day: day,
      information: information || '',
    }, {
      headers: {
        'Authorization': `${sessionStorage.getItem('admin')}`,
      },
    });
  },
  showNews() {
    return apiSimple.get('/showNews/');
  },
  createNews({ url, information }) {
    return apiClient.post('/createNews/', {
      url: url,
      information: information,
    });
  },
  createNewsFile({ filename, information }) {
    return apiFile.post('/createNews/', {
      filename : filename ,
      information: information,
    });
  },
  downloadPresse({ identification }) {
    return apiDownloadFile.post('/download/', {
      identification: identification,
    });
  },
  removeNews({ identification }) {
    return apiClient.delete(`/removeNews/${identification}`);
  },
  alterNews({ identification, filterData }) {
    return apiClient.post('/alterNews/', {
      identification: identification,
      ...filterData,
    });
  },
  activeGamesOverview() {
    return apiAdventureGolfAdmin.get('/golf/activeGamesOverview/');
  },
  login({ username, password }) {
    return apiClient.post('/checkCredentials/', {
      username: username,
      password: password,
      userType: 'admin',
    });
  },
  logout() {
    return apiSimple.get('/logout/');
  },
  tokenValid() {
    return apiClient.get('/tokenValid/', {
      headers: {
        'Authorization': `${sessionStorage.getItem('admin')}`,
      },
    });
  },

  //news ticker
  createNewsTicker({ startDate, endDate, pageConfig, heading, information, imageUrl, imageName, linkUrl }) {
    let params;
    if (imageName) {
      params = {
        imageName,
        startDate,
        endDate,
        pageConfig,
        heading,
        information,
        linkUrl,
      };
    } else if (imageUrl) {
      params = {
        startDate,
        endDate,
        pageConfig,
        heading,
        information,
        imageUrl,
        linkUrl,
      };
    } else {
      params = {
        startDate,
        endDate,
        pageConfig,
        heading,
        information,
        linkUrl,
      };
    }
    return apiClient.post('/news-sticker', params);
  },
  updateNewsTicker({ id, startDate, endDate, pageConfig, heading, information, imageUrl, imageName, linkUrl }) {
    let params = {
      startDate,
      endDate,
      pageConfig,
      heading,
      information,
      linkUrl,
    };
    if (imageName) {
      params = {
        ...params,
        imageName,
      };
    } else if (imageUrl !== undefined) {
      params = {
        ...params,
        imageUrl,
      };
    }
    return apiClient.post(`/news-sticker/${id}`, params);
  },
  showNewsTicker({ type }) {
    return apiClient.get(`/news-sticker?pageConfig=${type}&isAdmin=true`);
  },
  removeNewsTicker({ id }) {
    return apiClient.delete(`/news-sticker/${id}`);
  },
};