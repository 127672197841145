<template>
  <div>
    <navigation-admin :navigation-menus="navigationMenus" />
    <transition
      name="page"
      mode="out-in"
    >
      <slot />
    </transition>
  </div>
</template>

<script>
import NavigationAdmin from '@/components/NavigationAdmin';
import { navigationMenus } from '@/configs/navigationAdmin';

export default {
  name: 'Admin',
  components: {
    NavigationAdmin,
  },
  computed: {
    navigationMenus() {
      return navigationMenus;
    },
  },
};
</script>
