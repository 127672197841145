export const navigationMenus = [
  {
    to: 'admin-booking',
    label: 'Kalender',
    color: 'tab-orange',
  },
  {
    to: 'admin-dashboard',
    label: 'DASHBOARD',
    color: 'tab-orange',
  },
  {
    to: 'admin-close',
    label: 'Geschlossene',
    color: 'tab-orange',
  },
  {
    to: 'admin-recurrent',
    label: 'Wiederkehrend',
    color: 'tab-orange',
  },
  {
    to: 'admin-presse',
    label: 'Presse',
    color: 'tab-orange',
  },
  {
    to: 'admin-news',
    label: 'News',
    color: 'tab-orange',
  },
  {
    to: 'home',
    label: 'Logout',
    color: 'tab-orange',
  },
];