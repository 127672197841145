var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'navigation-admin',
    _vm.$route.name === 'admin-dashboard' && 'gray-bg'
  ]},[_c('div',{staticClass:"navigation-box"},[_c('div',{staticClass:"logo-wrapper"},[_c('router-link',{staticClass:"logo",attrs:{"to":{ name: 'home' }}},[_c('img',{attrs:{"src":require('@/assets/Arena_Kitzingen.png')}})])],1),_c('div',{staticClass:"navigation-bar"},[(!_vm.isLoginPage)?[_vm._l((_vm.navigationMenus),function(nav,index){return [(nav.label === 'Logout')?_c('button',{key:`${nav.label}${index}`,class:['navigation-bar-item', nav.color],style:({
              borderColor: `var(--${nav.color})`
            }),on:{"click":_vm.onLogout}},[_vm._v(" "+_vm._s(nav.label)+" ")]):_c('router-link',{key:`${nav.to}${index}`,class:['navigation-bar-item', nav.color],style:({
              borderColor: `var(--${nav.color})`
            }),attrs:{"to":{ name: nav.to }}},[_vm._v(" "+_vm._s(nav.label)+" ")])]})]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }