import { apiClient } from '@/services';

export const AuthenticationInformation = {
  addAccount({ username, firstName, lastName, gender, membership, phone }) {
    return apiClient.post('/addAccount/', {
      username: username,
      firstName: firstName,
      lastName: lastName,
      gender: gender,
      membership: membership,
      phone: phone || '',
    });
  },
  accountConfirmation({ hash, password }) {
    return apiClient.post('/accountConfirmation/', {
      hash: hash,
      password: password,
    });
  },
  checkCredentials({ username, password }) {
    return apiClient.post('/checkCredentials/', {
      username: username,
      password: password,
      userType: 'user',
    });
  },
  requestResetPassword({ username }) {
    return apiClient.post('/resetPassword/', {
      username: username,
    });
  },
  resetPassword({ username, newPassword }) {
    return apiClient.post('/resetPassword/', {
      username: username,
      newPassword: newPassword,
    });
  },
  changeAccountPassword({ username, oldPassword, newPassword }) {
    return apiClient.post('/changeAccountPassword/', {
      username: username,
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  },
  checkTokenValidation(hash) {
    return apiClient.get('/checkTokenValidation/parameter', {
      params: {
        hash: hash,
      },
    });
  },
  resendValidToken(hash) {
    return apiClient.post('/resendValidToken/', {
      hash: hash,
    });
  },
};