import Vue from 'vue';
import Vuetify, {
  VCalendar,
  VCalendarCategory,
  VCalendarDaily,
  VCalendarMonthly,
  VCalendarWeekly,
  VDatePicker,
  VDatePickerDateTable,
  VDatePickerMonthTable,
  VDatePickerYears,
  VDatePickerHeader,
  VDatePickerTitle,
  VMenu,
} from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
  components: {
    VCalendar,
    VCalendarCategory,
    VCalendarDaily,
    VCalendarMonthly,
    VCalendarWeekly,
    VDatePicker,
    VDatePickerDateTable,
    VDatePickerMonthTable,
    VDatePickerYears,
    VDatePickerHeader,
    VDatePickerTitle,
    VMenu,
  },
});

const opts = {
};

export default new Vuetify(opts);