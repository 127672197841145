import { ActivityInformation } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import {
  GET_UPCOMING_EVENTS,
  SET_SCORE_TICKER,
} from '../mutationTypes';

const state = {
  scoreTicker: {},
  upcomingEvents: [],
};

const mutations = {
  [SET_SCORE_TICKER](stateData, data) {
    stateData.scoreTicker = data;
  },
  [GET_UPCOMING_EVENTS](stateData, data) {
    stateData.upcomingEvents = data;
  },
};

const actions = {
  async scoreTicker({ commit }) {
    const response = await ActivityInformation.scoreTicker();

    if (response.status === networkStatus['ok']) {
      commit(SET_SCORE_TICKER, response.data[0]);
    }

    return response;
  },
  async getUpcomingEvents({ commit }) {
    const response = await ActivityInformation.getUpcomingEvents();

    if (response.status === networkStatus['ok']) {
      commit(GET_UPCOMING_EVENTS, response.data);
    }

    return response;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
