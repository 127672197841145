import { apiClient, apiAdventureGolfClient } from '@/services';
import { getLoginUserData } from '@/helpers';

export const BookingInformation = {
  addBooking({ day, timeFrom, timeTo, type, information, telephone, company, email, name }) {
    return apiAdventureGolfClient.post('/addBooking/', {
      day: day,
      timeFrom: timeFrom,
      timeTo: timeTo,
      type: type,
      name: name,
      information: information || '',
      company: company || '',
      telephone: telephone,
      email: email,
      pageConfig: 'homepage_arena',
    }, {
      headers: {
        'Authorization': getLoginUserData()
          ? `Bearer ${getLoginUserData().accessToken}`
          : null,
      },
    });
  },
  showBookings({ dateFrom, dateTo, type }) {
    return apiClient.post('/showBookings/', {
      dateFrom: dateFrom,
      dateTo: dateTo,
      type: type,
    });
  },
  getActivitySlot() {
    return apiClient.get('/getActivitySlot/parameter');
  },
  showNotices({ dateFrom, activityType, statusType }) {
    return apiAdventureGolfClient.get('/showNotices/', {
      params: {
        dateFrom: dateFrom || '',
        activityType: activityType,
        statusType: statusType || '',
      },
    });
  },
  showUserBookings() {
    return apiAdventureGolfClient.post('/showBookings/');
  },
  cancelBooking({ identification, eliminator }) {
    return apiClient.post('/cancelBooking/', {
      identification: identification,
      eliminator: eliminator || 't',
    }, {
      headers: {
        'Authorization': getLoginUserData()
          ? `Bearer ${getLoginUserData().accessToken}`
          : null,
      },
    });
  },
  alterBooking({ identification, day, timeFrom, timeTo, type, information, name, company, telephone, email }) {
    return apiAdventureGolfClient.post('/alterBooking/', {
      identification: identification,
      day: day,
      type: type,
      timeFrom: timeFrom,
      timeTo: timeTo,
      information: information || '',
      name: name,
      company: company || '',
      telephone: telephone || '',
      email: email || '',
      pageConfig: 'homepage_arena',
    }, {
      headers: {
        'Authorization': getLoginUserData()
          ? `Bearer ${getLoginUserData().accessToken}`
          : null,
      },
    });
  },
};