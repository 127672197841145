import { ActivityInformation } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import {
  LOAD_UPCOMING_EVENTS,
  LOAD_ADVERTISE_IMAGE,
  LOAD_ACTIVITIES,
  SET_OPEN_LOGIN_DROPDOWN,
} from '../mutationTypes';

const state = {
  upcomingEvents: [],
  advertiseImage: {
    home: [],
  },
  activities: [],
  isOpenLoginDropown: false,
};

const mutations = {
  [LOAD_UPCOMING_EVENTS](stateData, data) {
    stateData.upcomingEvents = [
      ...data,
    ];
  },
  [LOAD_ADVERTISE_IMAGE](stateData, { location, data }) {
    stateData.advertiseImage = {
      ...stateData.advertiseImage,
      [location]: [...data],
    };
  },
  [LOAD_ACTIVITIES](stateData, data) {
    stateData.activities = [
      ...data,
    ];
  },
  [SET_OPEN_LOGIN_DROPDOWN](stateData, data) {
    stateData.isOpenLoginDropown = data;
  },
};

const actions = {
  async loadUpcomingEvensts({ commit }) {
    const response = await ActivityInformation.showNewsTicker();

    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return;
      }

      commit(LOAD_UPCOMING_EVENTS, response.data);
    }

    return response;
  },
  async showAdvertising({ commit, state: stateData }, data) {
    if (stateData.advertiseImage[data.location].length !== 0) {
      return;
    }

    const response = await ActivityInformation.showAdvertising(data);
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return;
      }
      const filteredTime = response.data.filter((item) => {
        if (item.endDate.charAt(0) === '-') {
          return true;
        } else {
          return new Date() < Date.parse(item.endDate);
        }
      });

      commit(LOAD_ADVERTISE_IMAGE, {
        location: data.location,
        data: filteredTime,
      });
    }

    return response;
  },
  async getAllActivities({ commit }) {
    const response = await ActivityInformation.getActivities();
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return;
      }
      commit(LOAD_ACTIVITIES, response.data);
    }
    return response;
  },
  setOpenLoginDropdown({ commit }, data) {
    commit(SET_OPEN_LOGIN_DROPDOWN, data);
  },
};

export const getters = {
  getArenaActivitiesName: (stateData) => {
    return stateData.activities.filter((item) => item.arenaType === 'arena').map((filteredItem) => filteredItem.activityName);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
