<template>
  <div class="base-input">
    <input
      v-if="type !== 'number'"
      :id="inputId"
      :value="value"
      :type="hasEye ? passwordType : type"
      @input="onInput"
      @keyup.enter="onEnter"
    >
    <input
      v-else
      :id="inputId"
      :value="value"
      :type="type"
      @keyup.enter="onEnter"
      @keypress="keypressNumberHandler"
      @input="onInput"
    >
    <button
      v-if="type === 'password' && hasEye"
      class="eye-password"
      tabindex="-1"
      @click="onClickEye"
    >
      <icon-eye :slash="isShowPassword" />
    </button>
    <div
      v-if="placeholder !== ''"
      :class="[
        'placeholder',
        required && 'required',
        `${value}`.replace(/[^a-zA-Z0-9]/g, '') !== '' && 'active',
        alwaysActive && 'active'
      ]"
    >
      {{ placeholder }}
    </div>
    <label
      v-if="errorMsg !== ''"
      :for="inputId"
      class="error-msg"
    >
      <p>{{ errorMsg }}</p>
    </label>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/base-input.scss";
</style>

<script>
import IconEye from '@/components/icons/IconEye';

export default {
  components: {
    IconEye,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    inputId: {
      type: String,
      default: 'baseInputId',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    alwaysActive: {
      type: Boolean,
      default: false,
    },
    hasEye: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShowPassword: false,
      passwordType: 'password',
    };
  },
  methods: {
    onInput(e) {
      const value = e.target.value;
      this.$emit('input', value);
    },
    onEnter() {
      this.$emit('on-enter');
    },
    onClickEye() {
      this.isShowPassword = !this.isShowPassword;

      if (this.isShowPassword) {
        this.passwordType = 'text';
      } else {
        this.passwordType = 'password';
      }
    },
    keypressNumberHandler(e) {
      const charCode = (e.which) ? e.which : e.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        this.$emit('keypress', false);
        e.preventDefault();
      } else {
        this.$emit('keypress', true);
      }
    },
  },
};
</script>