import { BookingInformation } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import {
  LOAD_BOOKING,
  LOAD_ACTIVITY_SLOT,
  LOAD_NOTICES,
  UPDATE_BOOKING,
} from '../mutationTypes';
require('@/plugins/datejs/date-de-DE');

const state = {
  booking: {},
  activitySlots: {},
  notices: [],
};

const mutations = {
  [LOAD_BOOKING](stateData, data) {
    stateData.booking = {
      ...stateData.booking,
      [`${data.type}Events`]: data.data,
    };
  },
  [UPDATE_BOOKING](stateData, data) {
    stateData.booking = {
      ...stateData.booking,
      [`${data.type}Events`]: data.data,
    };
  },
  [LOAD_ACTIVITY_SLOT](stateData, data) {
    stateData.activitySlots = {
      ...data,
    };
  },
  [LOAD_NOTICES](stateData, data) {
    stateData.notices = data.filter((item) => item.status === 'Closed');
  },
};

const actions = {
  async showBookings({ commit }, data) {
    const response = await BookingInformation.showBookings(data);

    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false || (response.data.details && response.data.details.length === 0)) {
        commit(LOAD_BOOKING, {
          type: data.type,
          data: [],
        });
        return;
      }

      commit(LOAD_BOOKING, {
        type: data.type,
        data: response.data,
      });
    } else if (response.status === networkStatus['no_content']) {
      commit(LOAD_BOOKING, {
        type: data.type,
        data: [],
      });
    }

    return response;
  },
  async getActivitySlot({ commit }) {
    const response = await BookingInformation.getActivitySlot();

    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        commit(LOAD_ACTIVITY_SLOT, {});
        return;
      }

      commit(LOAD_ACTIVITY_SLOT, response.data);
    }  else if (response.status === networkStatus['no_content']) {
      commit(LOAD_ACTIVITY_SLOT, {});
    }
  },
  async showNotices({ commit }, data = {}) {
    const response = await BookingInformation.showNotices(data);
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return;
      }
      commit(LOAD_NOTICES, response.data);
    }
  },
  async editBooking({ commit, state: stateData  }, data) {
    const removeStateEditItems = stateData.booking[`${data.activityName}Events`]
      .filter((item) => item.identification !== data.identification);
    const payload = [
      ...removeStateEditItems,
      data,
    ];

    commit(UPDATE_BOOKING, {
      type: data.activityName,
      data: payload,
    });
  },
  async deleteBooking({ commit, state: stateData  }, data) {
    const response = await BookingInformation.cancelBooking(data);
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return Promise.reject();
      }

      const removeStateEditItems = stateData.booking[`${data.type}Events`]
        .filter((item) => item.identification !== data.identification);

      commit(UPDATE_BOOKING, {
        type: data.type,
        data: removeStateEditItems,
      });
    }
    return response;
  },
};

const getters = {
  getCloseDates: (stateData) => {
    let closeDates = [];
    stateData.notices.forEach((notice) => {
      const splitDateFrom = notice.startDatetime.split(' ')[0].split('-');
      const dateFromSlashFormat = `${splitDateFrom[0]}/${parseInt(splitDateFrom[2])}/${parseInt(splitDateFrom[1])}`; // month/day/year - 7/1/2004
      const splitDateTo = notice.endDatetime.split(' ')[0].split('-');
      const dateToSlashFormat = `${splitDateTo[0]}/${parseInt(splitDateTo[2])}/${parseInt(splitDateTo[1])}`; // month/day/year - 7/1/2004
      if (dateFromSlashFormat === dateToSlashFormat) {
        const parsedDateTime = Date.parse(dateFromSlashFormat);
        const dateData = parsedDateTime.toString('yyyy-MM-dd');
        closeDates.push(dateData);
      } else {
        let startDateTime = Date.parse(dateFromSlashFormat);
        const endDateTime = Date.parse(dateToSlashFormat);
        while (startDateTime.compareTo(Date.parse(endDateTime)) < 1) {
          const dateData = startDateTime.toString('yyyy-MM-dd');
          closeDates.push(dateData);
          startDateTime = startDateTime.addDays(1);
        }
      }
    });
    return closeDates;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
