const app = document.body;
const node = document.createElement('div');
const loadSpinnerBg = document.createElement('div');
const loadSpinnerWrapper = document.createElement('div');
const loaderSpinner = document.createElement('div');
node.setAttribute('class', 'app-loader');
loadSpinnerBg.setAttribute('class', 'main-loader-spinner-bg');
loadSpinnerWrapper.setAttribute('class', 'main-spinner-wrapper');
loaderSpinner.setAttribute('class', 'main-spinner spinner-border text-primary');
loadSpinnerWrapper.appendChild(loaderSpinner);
node.appendChild(loadSpinnerBg);
node.appendChild(loadSpinnerWrapper);

export const loader = {};
loader.start = () => app.appendChild(node);
loader.stop = () => {
  if (document.body.contains(node)) {
    app.removeChild(node);
  }
};