<template>
  <b-modal
    :id="id"
    :size="size"
    :dialog-class="[
      'base-modal', plain !== '' ? 'plain' : null,
      'base-modal', admin ? 'admin' : null
    ]"
    :body-class="customClass"
    :scrollable="scrollable"
    :header-class="[plain !== '' ? 'base-modal-header-plain' : 'base-modal-header']"
    :no-close-on-backdrop="noCloseOnBackdrop"
    centered
    hide-footer
    @shown="onShown"
    @show="onShow"
    @hide="$emit('hide')"
  >
    <template #modal-header="{ hide }">
      <template v-if="admin">
        <div :class="['admin-header', error && 'error']">
          <p class="title">
            {{ title }}
          </p>
          <button @click="onHide(hide)">
            <icon-close
              fill="#F09009"
              size="18"
            />
          </button>
        </div>
      </template>
      <template v-else-if="plain !== ''">
        <div :class="['plain-header', plain]">
          <p class="title">
            {{ title }}
          </p>
          <button @click="onHide(hide)">
            <icon-close
              class="close-button"
              fill="#fff"
              size="18"
            />
          </button>
        </div>
      </template>
      <template v-else>
        <div class="color bg-green" />
        <div class="color bg-blue" />
        <div class="color bg-orange" />
        <div class="color bg-purple" />
        <button @click="onHide(hide)">
          <icon-close
            class="close-button"
            fill="#fff"
            size="18"
          />
        </button>
      </template>
    </template>
    <template #default="{ hide, close }">
      <slot
        :hide="hide"
        :close="close"
      />
    </template>
  </b-modal>
</template>

<style lang="scss">
@import '@/styles/components/base-modal.scss';
</style>

<script>
import IconClose from '@/components/icons/IconClose';

export default {
  components: {
    IconClose,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    customClass: {
      type: String,
      default: '',
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    plain: {
      type: String,
      default: '',
    },
    admin: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onHide(callback) {
      this.$emit('hide');
      callback();
    },
    onShown() {
      this.$emit('shown');
    },
    onShow() {
      this.$emit('show');
    },
  },
};
</script>