
import { AdminInformation } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import router from '@/router';
import {
  ADMIN_UPDATE_EVENTS,
  ADMIN_UPDATE_RECURRENT_EVENTS,
  ADMIN_UPDATE_PRESSE_DATA,
  ADMIN_UPDATE_ADD_RECURRENT_VALIDATION,
  ADMIN_UPDATE_ADD_BOOKING_VALIDATION,
  ADMIN_UPDATE_PREVIEW_NEWS,
  ADMIN_UPDATE_SAVE_NEWS_EDIT_STATE,
  ADMIN_LOAD_NOTICES,
  SET_ACTIVE_GAMES_OVERVIEW,
} from '../mutationTypes';

const state = {
  notices: [],
  events: [],
  recurrentEvents: [],
  presseData: [],
  addRecurrentValidateStatus: false,
  addBookingValidateStatus: false,
  activeGamesOverview: {},

  previewNews: [],
  savedNewsEditState: null,
};

const mutations = {
  [ADMIN_UPDATE_EVENTS](stateData, data) {
    stateData.events = [
      ...data,
    ];
  },
  [ADMIN_UPDATE_RECURRENT_EVENTS](stateData, data) {
    stateData.recurrentEvents = [
      ...data,
    ];
  },
  [ADMIN_UPDATE_PRESSE_DATA](stateData, data) {
    stateData.presseData = [
      ...data,
    ];
  },
  [ADMIN_UPDATE_ADD_RECURRENT_VALIDATION](stateData, data) {
    stateData.addRecurrentValidateStatus = data;
  },
  [ADMIN_UPDATE_ADD_BOOKING_VALIDATION](stateData, data) {
    stateData.addBookingValidateStatus = data;
  },
  [ADMIN_LOAD_NOTICES](stateData, data) {
    stateData.notices = [...data];
  },
  [ADMIN_UPDATE_PREVIEW_NEWS](stateData, data) {
    stateData.previewNews = [...data];
  },
  [ADMIN_UPDATE_SAVE_NEWS_EDIT_STATE](stateData, data) {
    stateData.savedNewsEditState = {...data};
  },
  [SET_ACTIVE_GAMES_OVERVIEW](stateData, data) {
    stateData.activeGamesOverview = data;
  },
};

const actions = {
  async showNotices({ commit }, data = {}) {
    const response = await AdminInformation.showNotices(data);
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return;
      }
      commit(ADMIN_LOAD_NOTICES, response.data);
    } else if (response.status === networkStatus['no_content']) {
      commit(ADMIN_LOAD_NOTICES, []);
    }
  },
  async showAllEvents({ commit }, data) {
    const response = await AdminInformation.showAllEvents(data);

    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false || (response.data.details && response.data.details.length === 0)) {
        commit(ADMIN_UPDATE_EVENTS, []);
        return;
      }

      commit(ADMIN_UPDATE_EVENTS, response.data);
    } else if (response.status === networkStatus['no_content']) {
      commit(ADMIN_UPDATE_EVENTS, []);
    }

    return response;
  },
  async cancelBooking({ commit, state: stateData }, data) {
    const response = await AdminInformation.cancelBooking({
      identification: data,
    });

    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return;
      }

      const payload = stateData.events.slice().filter((item) => item.identification !== data);
      commit(ADMIN_UPDATE_EVENTS, payload);
    }

    return response;
  },
  async alterBooking({ commit, state: stateData }, data) {
    const response = await AdminInformation.alterBooking(data);

    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return Promise.reject();
      }

      const payload = stateData.events.slice().map((item) => {
        if (item.identification === data.identification) {
          const maName = data.filters && data.filters.maName
            ? data.filters.maName
            : '';
          const invoice = data.filters && data.filters.invoice
            ? data.filters.invoice
            : '0';
          return {
            ...data,
            activityName: data.type,
            bookingDay: data.day,
            maName,
            invoice,
          };
        } else {
          return { ...item };
        }
      });
      commit(ADMIN_UPDATE_EVENTS, payload);
    }

    return response;
  },
  async removeCloseDate({ commit, rootState, state: stateData }, data) {
    const response = await AdminInformation.removeNotice(data);

    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return Promise.reject();
      }
      const adminPayload = stateData.notices.slice().filter((item) => item.identification !== data.identification);
      commit(ADMIN_LOAD_NOTICES, adminPayload);
      const payload = rootState.booking.notices.slice().filter((item) => item.identification !== data.identification);
      commit('booking/LOAD_NOTICES', payload, { root: true });
    }

    return response;
  },
  async showRecurrentEvents({ commit }) {
    const response = await AdminInformation.showRecurrentEvents();

    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false || (response.data.details && response.data.details.length === 0)) {
        return Promise.reject();
      }

      commit(ADMIN_UPDATE_RECURRENT_EVENTS, response.data);
    } else if (response.status === networkStatus['no_content']) {
      commit(ADMIN_UPDATE_RECURRENT_EVENTS, []);
    }

    return response;
  },
  async alterRecurringEvent({ commit, state: stateData }, data) {
    const response = await AdminInformation.alterRecurringEvent(data);

    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return Promise.reject(response.data);
      }

      const payload = stateData.recurrentEvents.slice().map((item) => {
        if (item.discriminator === data.discriminator) {
          return {
            ...data,
            activityType: data.type,
            weekNames: data.weekNumber,
            timeTo: data.timeTo.slice(0, -3),
            timeFrom: data.timeFrom.slice(0, -3),
          };
        } else {
          return { ...item };
        }
      });
      commit(ADMIN_UPDATE_RECURRENT_EVENTS, payload);
    }

    return response;
  },
  async removeRecurringEvent({ commit, state: stateData }, data) {
    const response = await AdminInformation.removeRecurringEvent(data);

    if (response.status === networkStatus['ok']) {
      const payload = stateData.recurrentEvents.slice().filter((item) => item.discriminator !== data.discriminator);
      commit(ADMIN_UPDATE_RECURRENT_EVENTS, payload);
    }

    return response;
  },
  async showRecurringEventsDetails({ commit, state: stateData }, data) {
    const response = await AdminInformation.showRecurringEventsDetails(data);

    if (response.status === networkStatus['ok']) {
      const payload = stateData.recurrentEvents.slice().map((item) => {
        if (item.discriminator === data.discriminator) {
          return {
            ...item,
            details: response.data,
          };
        } else {
          return { ...item };
        }
      });
      commit(ADMIN_UPDATE_RECURRENT_EVENTS, payload);
    } else if (response.status === networkStatus['no_content']) {
      commit(ADMIN_UPDATE_RECURRENT_EVENTS, []);
    }

    return response;
  },
  async validateRecurringEventPossibility({ commit }, data) {
    let response;
    try {
      response = await AdminInformation.validateRecurringEventPossibility(data);
      commit(ADMIN_UPDATE_ADD_RECURRENT_VALIDATION, true);

    } catch {
      commit(ADMIN_UPDATE_ADD_RECURRENT_VALIDATION, false);
    }

    return response;
  },
  async validateBookingPossibility({ commit }, data) {
    let response;
    try {
      response = await AdminInformation.validateBookingPossibility(data);
      if (response.data.message) {
        commit(ADMIN_UPDATE_ADD_BOOKING_VALIDATION, true);
      } else {
        commit(ADMIN_UPDATE_ADD_BOOKING_VALIDATION, false);
      }

    } catch {
      commit(ADMIN_UPDATE_ADD_BOOKING_VALIDATION, false);
    }

    return response;
  },
  async showPresse({ commit }) {
    let response;
    try {
      response = await AdminInformation.showNews();
      if (response.status === networkStatus['ok']) {
        commit(ADMIN_UPDATE_PRESSE_DATA, response.data);
      } else if (response.status === networkStatus['no_content']) {
        commit(ADMIN_UPDATE_PRESSE_DATA, []);
      }
    } catch {
      commit(ADMIN_UPDATE_PRESSE_DATA, []);
    }

    return response;
  },
  async removePresse({ commit, state: stateData }, data) {
    const response = await AdminInformation.removeNews(data);

    if (response.status === networkStatus['ok']) {
      const payload = stateData.presseData.slice().filter((item) => item.identification !== data.identification);
      commit(ADMIN_UPDATE_PRESSE_DATA, payload);
    }

    return response;
  },
  async alterNews({ commit, state: stateData }, data) {
    const response = await AdminInformation.alterNews(data);

    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        return Promise.reject(response.data);
      }
      const payload = stateData.presseData.slice().map((item) => {
        if (item.identification === data.identification) {
          return { ...data };
        } else {
          return { ...item };
        }
      });

      commit(ADMIN_UPDATE_PRESSE_DATA, payload);
    }

    return response;
  },
  async activeGamesOverview({ commit }, data) {
    try {
      const response = await AdminInformation.activeGamesOverview(data);

      if (response.status === networkStatus['ok']) {
        commit(SET_ACTIVE_GAMES_OVERVIEW, response.data);
      }

      return response;
    } catch(err) {
      AdminInformation.logout().then(() => {
        sessionStorage.removeItem('admin');
        router.push({ name: 'admin-login' });
      });
    }
  },
  updatePreviewNews({ commit }, data) {
    commit(ADMIN_UPDATE_PREVIEW_NEWS, data);
  },
  updateSaveNewsEditState({ commit }, data) {
    commit(ADMIN_UPDATE_SAVE_NEWS_EDIT_STATE, data);
  },
};

export const getters = {
  getArenaRecurrents: (stateData, getters, rootState, rootGetters) => {
    const arenaActivitiesName = rootGetters['base/getArenaActivitiesName'];
    return stateData.recurrentEvents.filter((item) => arenaActivitiesName.includes(item.activityType));
  },
  getGeneralRecurrents: (stateData, getters, rootState, rootGetters) => {
    const arenaActivitiesName = rootGetters['base/getArenaActivitiesName'];
    return stateData.recurrentEvents.filter((item) => !arenaActivitiesName.includes(item.activityType));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
