export const LOAD_BOOKING = 'LOAD_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const LOAD_ACTIVITY_SLOT = 'LOAD_ACTIVITY_SLOT';
export const LOAD_NOTICES = 'LOAD_NOTICES';

export const LOAD_UPCOMING_EVENTS = 'LOAD_UPCOMING_EVENTS';
export const LOAD_ADVERTISE_IMAGE = 'LOAD_ADVERTISE_IMAGE';
export const SET_SCORE_TICKER = 'SET_SCORE_TICKER';
export const LOAD_ACTIVITIES = 'LOAD_ACTIVITIES';
export const LOAD_SERVER_TIME = 'LOAD_SERVER_TIME';
export const SET_OPEN_LOGIN_DROPDOWN = 'SET_OPEN_LOGIN_DROPDOWN';
export const GET_UPCOMING_EVENTS = 'GET_UPCOMING_EVENTS';

// Admin
export const ADMIN_UPDATE_EVENTS = 'ADMIN_UPDATE_EVENTS';
export const ADMIN_UPDATE_RECURRENT_EVENTS = 'ADMIN_UPDATE_RECURRENT_EVENTS';
export const ADMIN_UPDATE_PRESSE_DATA = 'ADMIN_UPDATE_PRESSE_DATA';
export const ADMIN_UPDATE_ADD_RECURRENT_VALIDATION = 'ADMIN_UPDATE_ADD_RECURRENT_VALIDATION';
export const ADMIN_UPDATE_ADD_BOOKING_VALIDATION = 'ADMIN_UPDATE_ADD_BOOKING_VALIDATION';
export const ADMIN_UPDATE_PREVIEW_NEWS = 'ADMIN_UPDATE_PREVIEW_NEWS';
export const ADMIN_UPDATE_SAVE_NEWS_EDIT_STATE = 'ADMIN_UPDATE_SAVE_NEWS_EDIT_STATE';
export const ADMIN_LOAD_NOTICES = 'ADMIN_LOAD_NOTICES';
export const SET_ACTIVE_GAMES_OVERVIEW = 'SET_ACTIVE_GAMES_OVERVIEW';

// Authentication
export const SET_USER_LOGIN_STATUS = 'SET_USER_LOGIN_STATUS';
export const SET_USER_LOGIN_DATA = 'SET_USER_LOGIN_DATA';

// User
export const USER_GET_BOOKING_DATA = 'USER_GET_BOOKING_DATA';
export const USER_UPDATE_EVENTS = 'USER_UPDATE_EVENTS';