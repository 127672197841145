import Vue from 'vue';
import VueRouter from 'vue-router';

import AdminLayout from '@/layouts/AdminLayout';
import MainLayout from '@/layouts/MainLayout';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: {
      name: 'admin-booking',
    },
  },
  {
    path: '/admin',
    redirect: {
      name: 'admin-booking',
    },
  },
  {
    path: '/admin/login',
    name: 'admin-login',
    component: () => import(/* webpackChunkName: "admin-login" */ '@/pages/admin/login'),
    meta: {
      title: 'LOGIN ADMIN',
      layout: AdminLayout,
    },
    beforeEnter: (to, from, next) => {
      // Prevent user go back to Login page when already logged in
      const isLogin = sessionStorage.getItem('admin');

      if (isLogin) {
        next({
          name: 'admin-booking',
        });
      } else {
        next();
      }
    },
  },
  {
    path: '/admin/booking',
    name: 'admin-booking',
    component: () => import(/* webpackChunkName: "admin-booking" */ '@/pages/admin/booking'),
    meta: {
      title: 'Admin - Booking',
      layout: AdminLayout,
    },
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import(/* webpackChunkName: "admin-dashboard" */ '@/pages/admin/dashboard'),
    meta: {
      title: 'Admin - Dashboard',
      layout: AdminLayout,
    },
  },
  {
    path: '/admin/close',
    name: 'admin-close',
    component: () => import(/* webpackChunkName: "admin-close" */ '@/pages/admin/close'),
    meta: {
      title: 'Admin - Geschlossene',
      layout: AdminLayout,
    },
  },
  {
    path: '/admin/recurrent',
    name: 'admin-recurrent',
    component: () => import(/* webpackChunkName: "admin-recurrent" */ '@/pages/admin/recurrent'),
    meta: {
      title: 'Admin - Recurrent',
      layout: AdminLayout,
    },
  },
  {
    path: '/admin/presse',
    name: 'admin-presse',
    component: () => import(/* webpackChunkName: "admin-presse" */ '@/pages/admin/presse'),
    meta: {
      title: 'Admin - Presse',
      layout: AdminLayout,
    },
  },
  {
    path: '/admin/news',
    name: 'admin-news',
    component: () => import(/* webpackChunkName: "admin-news" */ '@/pages/admin/news'),
    meta: {
      title: 'Admin - News',
      layout: AdminLayout,
    },
  },
  {
    path: '/admin/news/preview/arena',
    name: 'admin-news-preview-arena',
    component: () => import(/* webpackChunkName: "admin-news-preview-arena" */ '@/pages/admin/news/preview/arena.vue'),
    meta: {
      title: 'Admin - Preview Arena News',
      layout: AdminLayout,
    },
  },
  {
    path: '/admin/news/preview/golf',
    name: 'admin-news-preview-golf',
    component: () => import(/* webpackChunkName: "admin-news-preview-golf" */ '@/pages/admin/news/preview/golf.vue'),
    meta: {
      title: 'Admin - Preview Golf News',
      layout: AdminLayout,
    },
  },
  {
    path: '/admin/history',
    name: 'admin-history',
    component: () => import(/* webpackChunkName: "admin-history" */ '@/pages/admin/history'),
    meta: {
      title: 'Admin - History',
      layout: AdminLayout,
    },
  },
  {
    path: '/error/404',
    name: 'error404',
    component: () => import(/* webpackChunkName: "error404" */ '@/pages/error/404'),
    meta: {
      title: '404 - Seite nicht gefunden',
      layout: MainLayout,
    },
  },
  {
    path: '*',
    redirect: {
      name: 'error404',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
    scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} - Arena Kitzingen` : 'Arena Kitzingen';
  if (to.meta.layout.name === 'Admin') {
    const isLogin = sessionStorage.getItem('admin');
    // if route is not public and not login then force to login page
    if (to.name === 'admin-login') {
      next();
    } else if (to.name !== 'admin-login' && !isLogin) {
      sessionStorage.removeItem('admin');
      next({
        name: 'admin-login',
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
