import axios from 'axios';
import { apiClient, apiSimple } from '@/services';

export const ActivityInformation = {
  getActivities() {
    return apiSimple.get('/getActivities/parameter');
  },
  showEvents() {
    return apiClient.post('/showEvents/');
  },
  showNewsTicker() {
    return apiClient.get('/showNewsTicker/parameter?pageConfig=homepage_arena');
  },
  scoreTicker() {
    return apiClient.get('/scoreTicker/');
  },
  showAdvertising({ location }) {
    return apiClient.post('/showAdvertising/', {
      location: location || 'home',
    });
  },
  getUpcomingEvents() {
    return apiClient.get('/upcomingEvents/');
  },
};
