const HTTP_OK = 200;
const HTTP_NO_CONTENT = 204;
const HTTP_SERVER_ERROR = 500;
const HTTP_UNAUTHORIZED = 401;
const HTTP_FAILED = 417;

export const networkStatus = Object.freeze({
  'ok': HTTP_OK,
  'no_content': HTTP_NO_CONTENT,
  'error': HTTP_SERVER_ERROR,
  'no_access': HTTP_UNAUTHORIZED,
  'failed': HTTP_FAILED,
});