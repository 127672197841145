import { AuthenticationInformation } from '@/services';
import { networkStatus } from '@/constants/networkStatus';
import {
  SET_USER_LOGIN_STATUS,
} from '../mutationTypes';

const state = {
  isUserLogin: false,
};

const mutations = {
  [SET_USER_LOGIN_STATUS](stateData, data) {
    stateData.isUserLogin = data;
  },
};

const actions = {
  async addAccount({ commit }, payload) {
    const response = await AuthenticationInformation.addAccount(payload);

    if (response.status === networkStatus['ok']) {
      if (response.data.messages) {
        return Promise.reject();
      }
      commit(SET_USER_LOGIN_STATUS, false);
    }

    return response;
  },
  async accountConfirmation({ commit }, payload) {
    const response = await AuthenticationInformation.accountConfirmation(payload);
    if (response.status === networkStatus['ok']) {
      if (response.data.messages) {
        return Promise.reject();
      }
      commit(SET_USER_LOGIN_STATUS, false);
    }
    return response;
  },
  async userLogin({ commit }, payload) {
    const response = await AuthenticationInformation.checkCredentials(payload);
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        commit(SET_USER_LOGIN_STATUS, false);
        return Promise.reject();
      }
      const stringify = JSON.stringify(response.data);
      localStorage.setItem('user_access', btoa(stringify));
      setTimeout(() => {
        commit(SET_USER_LOGIN_STATUS, true);
      }, 600);
    }
    return response;
  },
  async userChangePassword({ commit }, payload) {
    const response = await AuthenticationInformation.changeAccountPassword(payload);
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        commit(SET_USER_LOGIN_STATUS, true);
        return Promise.reject();
      }
      commit(SET_USER_LOGIN_STATUS, true);
    }
    return response;

  },
  async userResetPassword({ commit, dispatch }, payload) {
    const response = await AuthenticationInformation.resetPassword(payload);
    if (response.status === networkStatus['ok']) {
      if (response.data.successful === false) {
        commit(SET_USER_LOGIN_STATUS, true);
        return Promise.reject();
      }
      return dispatch('userLogin', {
        username: payload.username,
        password: payload.newPassword,
      });
    }
    return response;

  },
  setUserLoginState({ commit }, data) {
    setTimeout(() => {
      commit(SET_USER_LOGIN_STATUS, data);
    }, 600);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
