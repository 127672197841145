import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/styles/app.scss';
import BootstrapVue from 'bootstrap-vue';
import VueHead from 'vue-head';
import VueAnimate from 'vue-animate-scroll';
import { VLazyImagePlugin } from 'v-lazy-image';
import vuetify  from '@/plugins/vuetify';
import '@/plugins/scrollTo';

import BaseModal from '@/components/BaseModal';
import BaseInput from '@/components/BaseInput';

Vue.component('base-modal', BaseModal);
Vue.component('base-input', BaseInput);

Vue.use(VueHead);
Vue.use(BootstrapVue);
Vue.use(VueAnimate);
Vue.use(VLazyImagePlugin);

Vue.config.productionTip = false;

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
