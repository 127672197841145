<template>
  <div
    :class="[
      'navigation-admin',
      $route.name === 'admin-dashboard' && 'gray-bg'
    ]"
  >
    <div class="navigation-box">
      <div class="logo-wrapper">
        <router-link
          :to="{ name: 'home' }"
          class="logo"
        >
          <img :src="require('@/assets/Arena_Kitzingen.png')">
        </router-link>
      </div>
      <div class="navigation-bar">
        <template v-if="!isLoginPage">
          <template v-for="(nav, index) in navigationMenus">
            <button
              v-if="nav.label === 'Logout'"
              :key="`${nav.label}${index}`"
              :class="['navigation-bar-item', nav.color]"
              :style="{
                borderColor: `var(--${nav.color})`
              }"
              @click="onLogout"
            >
              {{ nav.label }}
            </button>
            <router-link
              v-else
              :key="`${nav.to}${index}`"
              :to="{ name: nav.to }"
              :class="['navigation-bar-item', nav.color]"
              :style="{
                borderColor: `var(--${nav.color})`
              }"
            >
              {{ nav.label }}
            </router-link>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/navigation-admin.scss";
</style>

<script>
import { AdminInformation } from '@/services';

export default {
  components: {
  },
  props: {
    navigationMenus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    isLoginPage() {
      return this.$route.name === 'admin-login';
    },
  },
  methods: {
    onLogout() {
      AdminInformation.logout().then(() => {
        sessionStorage.removeItem('admin');
        this.$router.push({ name: 'admin-login' });
      });
    },
  },
};
</script>
